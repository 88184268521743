/* You can add global styles to this file, and also import other style files */


html, body { height: 100%; }

body { 
    margin: 0; 
    font-family: "Inter", "Helvetica Neue", sans-serif  !important;  
    font-optical-sizing: auto; 
    font-style: normal; 
    font-variation-settings: "slnt" 0;
    color: #3c4043; 
}

.vertical-aling-out{
    display: table;
    height: 100%;
    width: 100%;
}

.vertical-aling-inner{
    display: table-cell;
    vertical-align: middle;
    width: 100%;
}

.ver-mais-pesquisa{
    margin-top: 30px;
}

.row {
    margin-bottom: 10px;
    margin-top: 25px;
}


/*------------------------Buttons--------------*/
.btn-primary {
    background-color: #A60000 !important;
    color: white !important;
}

    .btn-primary .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
        stroke: white !important;
    }

    .btn-primary:hover, .btn-secundary:hover,.btn-primary:focus, .btn-secundary:focus {
        text-decoration: none;
    }

    .btn-primary mat-spinner, .btn-secundary mat-spinner{
        margin: auto; 
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .btn-primary .material-symbols-outlined,
    .btn-secundary .material-symbols-outlined{
        vertical-align: middle;
    }

    .btn-primary:disabled{
        background-color: #cecece !important;
        color: #676767 !important
    }

.btn-secundary{
    border: 1px solid #A60000 !important;
    color: #A60000 !important;
}

    .btn-secundary .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
        stroke: #A60000 !important;
    }

    .btn-secundary mat-icon{
       vertical-align: bottom;
    }


.btn-link {
    color: #A60000 !important;
    text-decoration: none;;
}

    .btn-link:hover{
        text-decoration: none;;
    }

.button-big{
    width: 100%;
    max-width: 350px;
}

.fab-action{
    width: 30px !important;
    height: 30px !important;
    border-radius: 4px !important;
    display: block !important;
    margin: auto !important;
    margin-top: 3px !important;
    margin-bottom: 3px !important;
}

.fab-action.red{
    background-color: #EF4823;
}

.fab-action.red:hover{
    color: white;;
}

.fab-action.default{
    background-color: #A60000;
    text-align: center;
}

.fab-action.default:hover{
    color: white;;
}

.fab-action mat-icon{
    font-size: 23px;
    padding-top: 3px;
}

.fab-action .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: white !important;
}

.fab-action mat-spinner {
    margin-left: 7px;
}
/*------------------------END Buttons--------------*/




/*------------------------Angular componentes--------------*/
mat-form-field{
    width: 100%;
}

.material-symbols-outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24;
    line-height: normal;
    vertical-align: middle;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: rgba(0, 0, 0, .15) !important;

    &::before, &::after {
        border-color: #A60000 !important;
    }    
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected~.mdc-checkbox__ripple {
    background: #A60000;
}

.mat-mdc-checkbox.mat-accent {
    --mdc-checkbox-selected-focus-icon-color: #A60000 !important;
    --mdc-checkbox-selected-hover-icon-color: #A60000 !important;
    --mdc-checkbox-selected-icon-color: #A60000 !important;
    --mdc-checkbox-selected-pressed-icon-color: #A60000 !important;
}

.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate{
    background-color: #A60000 !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text{
    color: #A60000 !important;
}

.dialog-add-item mat-dialog-container{
    width: 850px;
}  
.dialog-add-item  mat-dialog-actions{
    display: block;
    padding: 10px 24px 24px 24px;
    width: 100%;
}

.dialog-search-add-client mat-dialog-container{
    width: 750px;
} 

.dialog-search-invite-client mat-dialog-container{
    width: 950px;
}

.grade-table .mat-mdc-form-field-subscript-wrapper{
    display: none;
}

.mdc-linear-progress__bar-inner{
    border-color: #A60000 !important;
}

.mat-mdc-progress-bar{
    --mdc-linear-progress-track-color: #fbe4d3 !important
}

.mdc-switch__track::after{
    background: #d27f7f !important;
}

html .mat-mdc-slide-toggle.mat-accent{
    --mdc-switch-selected-handle-color: #A60000!important;
    --mdc-switch-selected-hover-handle-color:#840000;
    --mdc-switch-selected-focus-handle-color:#840000;
    --mdc-switch-selected-pressed-handle-color: #740000;

}

.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: #A60000 !important;
}

.mat-internal-form-field>label{
    font-weight: 400;
    margin-bottom: 0;
}

.mat-mdc-header-cell > mat-icon{
    vertical-align: middle;
}

mat-slide-toggle mat-icon {
    vertical-align: middle;
}

/*------------------------END Angular componentes--------------*/


/*------------------------Angular componentes search bar--------------*/
.seach-bar .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before{
    border: none !important;
}

.seach-bar .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after{
    border-color: #A60000 !important;
}

.seach-bar .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label, .seach-bar .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above{
    color: #A60000 !important;   
}

.seach-bar .mdc-text-field--filled{
    border-top-left-radius:0px !important;
    border-top-right-radius:0px !important;
}
/*------------------------END Angular componentes search bar--------------*/


/*------------------------Angular CoreUI--------------*/
.carousel-indicators [data-coreui-target] {
    background-color: #A60000;
    width: 10px;
    height: 10px;
    border-top:unset;
    border-bottom:unset;
    border-radius: 50px;
}

.carousel-control-prev, .carousel-control-next{
    color: #A60000;
}
/*------------------------END Angular CoreUI--------------*/

.table-responsive{
    border: 2px solid #E6E6E6;
    padding: 9px 2px;
    margin-bottom: 15px;
    text-align: left;
}

    .table-responsive label{
        vertical-align: middle;
        margin-bottom: 0px;
        font-weight: 400;
        font-size: 14px;
    }

    .table-responsive > div{
        display: flex;
        align-items: center;
    }

    .table-responsive label.bold{
        font-weight: 500;
    }

    .table-responsive label.orange{
        color: #A60000;
    }


.title-default{
    border-left: 5px solid #A60000;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 7px;
    font-weight: 300 !important;
    font-size: 28px !important;
    color: #7A7A7A;
}

.subtitle-default{
    padding-top: 3px;
    padding-bottom: 3px;
    font-weight: 300 !important;
    font-size: 23px !important;
    color: #7A7A7A;
}
.search-area{
    margin-top: 25px;
}

.search-area .btn-secundary,
.search-area .btn-primary{
    height: 56px;
    padding-left: 30px;
    padding-right: 30px;
}  


.card-list {
    margin-bottom: 36px;
}

    .card-list .card-item {
        padding: 20px 18px 0px 18px;
        border: 1px solid #E6E6E6;
        border-bottom: 4px solid #A60000;
        margin-top: 15px;
        border-radius: 4px;
        height: calc(100% - 15px);
        align-content: flex-end;
    }

        .card-list .card-item .infos {
            display: inline-block;
            width: calc(100% - 78px);
        }

        .card-list .card-item .actions { 
            display: inline-block;
            width: 78px;
            text-align: right;
            padding-bottom: 4px;
            vertical-align: bottom;
        }

            .card-list .card-item .actions a,
            .card-list .card-item .actions button {
                text-align: center;
                width: 28px;
                height: 28px;
                display: inline-block;
                cursor: pointer;
                transition: all linear .1s;
                padding: 0px;
                background: #A60000;
                border-radius: 10px;
                color: #FFFFFF;
            }

        .card-list .card-item .tags { 
            display: block;
            width: 100%;
            text-align: left;
            padding-bottom: 8px;

        }

        .card-list .card-item .tags .tag-gray{ 
            display: inline-block;
            text-align: center;
            background-color: #EEEEEE;
            color: #A9A9A9;
            font-size: 12px;
            font-weight: 500;
            padding: 5px 10px;
            border-radius: 8px;
            margin-right: 5px;
            margin-top: 4px;
        }

        .card-list .card-item .tags .tag-orange{ 
            display: inline-block;
            text-align: center;
            background-color: #A60000;
            color: #FFFFFF;
            font-size: 12px;
            font-weight: 500;
            padding: 5px 10px;
            border-radius: 25px;
            margin-right: 5px;
        } 

        .card-list .card-item .infos span.title,
        .card-list .card-item .infos span.title-2,
        .card-list .card-item .infos span.title-3,
        .card-list .card-item .infos span.title-4{
            display: inline-block;
            width: 100%;
            min-height: 20px;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-bottom: 5px;
        }

        .card-list .card-item .infos span.title{
            font-weight: 600;
            font-size: 20px;
            color: #000000;
            font-weight: 600;
            overflow: hidden !important;
        }

        .card-list .card-item .infos span.title-2{
            font-size: 20px;
            font-weight: 500;
            color: #626768;
        }

        .card-list .card-item .infos span.title-3{
            font-size: 15px;
            font-weight: 600;
            color: #626768;
        }

            .card-list .card-item .infos span.title-3 img {
                width: 21px;
                height: 20px;
                margin-left: 10px;
            }

        .card-list .card-item .infos span.title-4{
            font-weight: 300;
            font-size: 15px;
            color: #BBBBBB;
        }

        .card-list .card-item .infos span{
            display: block;
            color: #BBBBBB;
            font-size: 14px;
            line-height: 135%;
        } 

.see-more{
    margin-top: 30px;
}

.title-buttons {
    display: flex;
    justify-content: flex-end;
}

    .title-buttons>button {
        width: 100%;
        margin-left: 10px;
    }
 
ngx-editor .NgxEditor__Content{
    min-height: 200px !important;
    display: block !important;
}

    @media (max-width: 500px) {
        .title-buttons {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            align-items: center;
        }

            .title-buttons>button {
                width: 100%;
                margin-bottom: 10px;
                margin-left: 0px;
            }
    }